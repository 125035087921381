.Button {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  transition: background-position 100ms;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='548' height='102' viewBox='0 0 548 102' preserveAspectRatio='none' xml:space='preserve'%3E%3Cg transform='matrix(1 0 0 1 274.25 51.5)' %3E%3Cpath style='stroke: none; fill: rgba(120,200,255,0.6); fill-rule: nonzero;' transform=' translate(-275, -147.5)' d='M 549.25 199 L 549.25 96.5 Q 501.25 176 447.25 170.5 L 98.25 171.5 Q 25.75 165.5 0.75 66 L 1.25 198.5 L 549.25 199' stroke-linecap='round' /%3E%3C/g%3E%3C/svg%3E");
  background-size: 110% 50%;
  background-repeat: no-repeat;
  background-position: center 150%;
}
.Button + .Button {
  margin-left: 10px;
}
.Button:hover:not(:disabled) {
  background-position: center 100%;
}
.Button:disabled {
  cursor: not-allowed;
  opacity: 0.85;
}
.Button--primary {
  color: white;
  background-color: #1ea7fd;
}
.Button--secondary {
  color: #333;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.Button--tiny {
  font-size: 12px;
  padding: 4px 12px;
}
.Button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.Button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.Button--large {
  font-size: 16px;
  padding: 12px 24px;
}
.Button--square {
  border-radius: 5px;
  background-size: 120% 45%;
}
.Button--primary:active,
.Button--primary.Button__active {
  background-color: rgba(30, 167, 253, 0.5);
}
.Button--secondary:active,
.Button--secondary.Button__active {
  background-color: rgba(80, 176, 204, 0.25);
}

.ButtonInline {
  border: none;
  background: none;
  padding: 2px 0px;
  cursor: pointer;
  text-align: left;
}
