.Spinner {
  display: inline-block;
  transition: visibility 100ms;
  visibility: hidden;
}

.Spinner--spinner {
  animation: spin 2s linear infinite;
}

.Spinner--img {
  width: 24px;
  height: 24px;
  transition: transform 100ms;
  transform: scale(0.1);
}

.Spinner__active {
  visibility: visible;
}

.Spinner__active .Spinner--img {
  transform: scale(1);
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
