.FileHeader--container {
  position: relative;
  border-bottom: 1px solid gray;
  background-color: rgba(249, 249, 249);
}

.FileHeader--container h1 {
  text-align: center;
  width: 100%;
}

.FileHeader--menu {
  position: absolute;
  top: 8px;
  left: 10px;
}

@media (min-width: 768px) {
  .FileHeader--container h1 {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  .FileHeader--menu {
    position: relative;
  }
}
