.Fraction {
  display: relative;
  color: rgba(0, 0, 0, 0.8);
}

.Fraction--num {
  position: relative;
  bottom: 0.05em;
  font-weight: 600;
}

.Fraction--den {
  position: relative;
  top: 0.25em;
  color: rgba(0, 0, 0, 0.6);
}

.Fraction--slash {
  font-size: 1.5em;
  vertical-align: top;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
}
