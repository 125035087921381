.Subitem {
  margin: 10px auto 20px -15px;
  display: flex;
}

.Subitem > div:nth-child(1) {
  width: 5px;
  border-radius: 2px;
  background: linear-gradient(
    190deg,
    rgb(244, 200, 200) 0%,
    rgb(254, 240, 240) 100%
  );
  flex: none;
}

.Subitem > div:nth-child(2) {
  width: 100%;
  padding-left: 10px;
}

@media (min-width: 500px) {
  .Subitem {
    margin-left: 0;
  }
  .Subitem > div:nth-child(1) {
    width: 10px;
    border-radius: 5px;
    background: linear-gradient(
      190deg,
      rgb(244, 220, 220) 0%,
      rgb(254, 245, 245) 100%
    );
  }

  .Subitem > div:nth-child(2) {
    padding-left: 15px;
  }
}
