.ingredientInput--name {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.ingredientInput--name > div {
  margin-top: 1rem;
}

.ingredientInput--facts {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.ingredientInput--factInputs {
  min-width: 100px;
  margin-right: 24px;
}

.ingredientInput--sizeUnit {
  min-width: 100px;
  margin-right: 6px;
}

.ingredientInput--factInputs input,
.ingredientInput--sizeUnit input {
  width: 100%;
}

.ingredientInput--search {
  transition: opacity 300ms;
  box-sizing: content-box;
  height: 14px;
}

.ingredientInput--search-label > span {
  display: none;
}

@media (min-width: 400px) {
  .ingredientInput--search-label {
    display: flex;
    align-items: center;
  }

  .ingredientInput--search-label > span {
    display: inline;
    margin-left: 5px;
  }
}

.ingredientInput__hidden {
  opacity: 0;
}

.CancelSearchAction {
  display: none;
}

@media (min-width: 500px) {
  .CancelSearchAction {
    display: block;
    transition: visibility 600ms, opacity 600ms 6s;
    visibility: visible;
    opacity: 1;
  }

  .CancelSearchAction__pre {
    visibility: hidden;
    opacity: 0;
  }
}

.SearchResultList {
  opacity: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

.SearchResultList__searching {
  opacity: 0.5;
}

.SearchResultItem {
  cursor: pointer;
}

.SearchResultItem--title {
  font-weight: bold;
}

.SearchResultItem:hover .SearchResultItem--title {
  text-decoration: underline;
}

.SearchResultItem--ingredients {
  display: block;
}
