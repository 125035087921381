body {
  margin: 0;
  font-size: 16px;
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  display: inline-block;
  vertical-align: top;
  margin: 0.8em 0;
}

h2 {
  font-weight: 900;
  font-size: 26px;
  line-height: 1;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  font-size: 16px;
}
