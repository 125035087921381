.m-2 {
  margin: 1em;
}

.mt-1 {
  margin-top: 0.5em;
}

.mt-2 {
  margin-top: 1em;
}

.mt-3 {
  margin-top: 1.5em;
}

.mt-4 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 0.5em;
}

.mb-2 {
  margin-bottom: 1em;
}

.mb-3 {
  margin-bottom: 1.5em;
}

.mb-4 {
  margin-bottom: 3rem;
}

.ml-1 {
  margin-left: 0.5em;
}

.ml-2 {
  margin-left: 1em;
}

.ml-3 {
  margin-left: 1.5em;
}

.pr-2 {
  padding-right: 1em;
}

.d-block {
  display: block;
}

.d-iblock {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.float-r {
  float: right;
}

.clear {
  clear: both;
}

.nowrap {
  white-space: nowrap;
}

.w-100 {
  width: 100%;
}
