.MenuButton--container {
  display: inline-block;
  position: relative;
}

.MenuButton--button {
  display: inline-flex;
}

.MenuButton--backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.MenuButton--options {
  position: absolute;
  padding: 5px 5px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.98);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px 2px 5px 5px;
}

.MenuButtonOption {
  list-style: none;
  border-radius: 0.2em;
}

.MenuButtonOption > button {
  padding: 4px 10px;
  width: 100%;
  font-size: 1em;
  min-width: 6em;
  text-align: left;
  cursor: pointer;
  border: none;
  background: none;
}

.MenuButtonOption:hover,
.MenuButtonOption:focus-within {
  background-color: rgba(100, 220, 255, 0.5);
}

.MenuButtonOption__clickfeedback {
  animation-duration: 250ms;
  animation-name: clickfeedback;
}

@keyframes clickfeedback {
  20% {
    background-color: rgba(100, 220, 255, 0);
  }

  to {
    background-color: rgba(100, 220, 255, 0.45);
  }
}

.MenuButtonOptionSeparator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 5px 10px;
}

.Chevron {
  transition: transform 250ms;
  margin-left: -0.2em;
  margin-right: 0.2em;
}

.Chevron__active {
  transform: rotate(90deg);
}
