.listManager--container > ul {
  max-height: 10rem;
  overflow-y: scroll;
  border: 1px solid #bbb;
  padding-left: 30px;
  margin: 1em 0;
}

.listManager--container > ul > li {
  margin-bottom: 8px;
}

.listManager__selectable li:not(.listManager--empty-item) {
  cursor: pointer;
}
