.CustomizedMeal--targetCals-row {
  max-width: 300px;
}

.CustomizedMeal--targetCals-row label {
  padding-right: 1.6em;
}

.CustomizedMeal table {
  width: 100%;
  max-width: 360px;
  margin: 1em auto;
  text-align: center;
}

.CustomizedMeal th:first-child {
  display: none;
}

.IngredientSummary--slider {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  display: block;
}

@media (min-width: 500px) {
  .IngredientSummary--slider {
    margin-top: 0;
    margin-bottom: 1em;
  }
}
